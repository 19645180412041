
@media (min-width:500px) {
    .item-card {
        margin-bottom: 20px;
        width: 300px;
    }
}

@media (max-width:499px) {
    .item-card {
        margin-bottom: 20px;
        width: 100%;
    }
}

@media (min-width:599px) {
    .items {
        margin: 75px 20px 20px 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: stretch;
    }
}

@media (max-width:499px) {
    .items {
        margin: 70px 20px 20px 20px;
    }
}