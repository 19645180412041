.contacts-icon {
    width: 177px;
    height: 130px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.contacts-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}